<template>
  <div>
    <el-container>
      <el-aside width="300px" :style="'height: ' + asideHeight + 'px'">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pageSize"
            layout="total, prev, next, jumper"
            :total="total">
        </el-pagination>
        <ul :style="'overflow:auto; height:' + (asideHeight-32) +'px'" class="ul">
          <li v-for="(item, index) in tempList" :key="index" style="" class="li">
            <el-row style="margin: 0">
              <el-col :span="10">
                <el-image :src="item.img" style="width: 100%; height: 100px" fit="fit"></el-image>
              </el-col>
              <el-col :span="14">
                <p class="Ptitle" @click="playVideo(item)" v-if="!itemIsChild(item)">{{ item.title }}</p>

                <el-popover v-else
                            placement="right"
                            width="400"
                            trigger="click">
                  <ul style="overflow:auto; height:200px">
                    <li v-for="(chin, indexI) in itemParsingUrl(item.link)" :key="indexI"><p @click="playVideo({
            'img': item['img'],'title': item.title + '-' + chin['title'], 'link': chin['link'] })" style="cursor: pointer;">{{ chin.title }}</p>
                    </li>
                  </ul>
                  <p class="Ptitle" slot="reference">{{ item.title }}</p>
                </el-popover>
              </el-col>
            </el-row>
          </li>
        </ul>
      </el-aside>
      <el-container>
        <el-header>
          <p style="margin: 10px" class="Ptitle">{{ title }}</p>
        </el-header>
        <el-main>
          <div style="width: 70%; text-align: center; padding-left: 15%; padding-top: 20px">
            <div>
              <Artplayer ref="artplayer" :muted="true"/>
            </div>
          </div>
        </el-main>
        <el-footer>
          <el-row type="flex" class="row-bg" justify="space-between">
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="watchTogether">一起看</el-button>
            </el-col>
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="downFile" v-if="$store.state.user.userInfo.luAdmin">下载
              </el-button>
            </el-col>
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="jumpPlay">跳转播放</el-button>
            </el-col>
            <el-col :span="4" class="footerDiv">
              <el-button type="button" size="small" @click="drawer = true">打开列表</el-button>
            </el-col>
            <el-col :span="2" class="footerDiv">
              <el-button type="button" size="small" @click="dialogVisible = true">搜索</el-button>
            </el-col>
          </el-row>
        </el-footer>
      </el-container>
    </el-container>

    <el-drawer
        title="播放列表"
        :visible.sync="drawer"
        :direction="direction"
        size="100%"
        custom-class="drawer"
        :before-close="handleClose">
      <p>资源</p>
      <div>
        <el-radio-group v-model="pvType" @change="resTypeChange">
          <el-radio-button :label="item.id" v-for="(item, index1) in pvList" :key="index1">{{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <p>类型</p>
      <div v-if="pvType ===1">
        <section v-if="allListShow">
          <div>
            <ul class="ul1" style="height:95vh;overflow-y:scroll;">
              <li class="li1" v-for="(instance, index) in fileName" :key="index"
                  @click="loadFileData(instance, index);">
                <a :class="[listIndex === index ? 'liChoose' : 'liNoChoose']"> <span class="text"> {{
                    instance.substring(0, instance.lastIndexOf('.json'))
                  }} </span></a>
              </li>
            </ul>
          </div>
        </section>
        <div v-else>
          <el-row>
            <el-col :span="24">
              <el-cascader-panel :options="options" @change="changeTree" ref="panel" :key="cascaderKey"/>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-else>
        <el-row>
          <el-col :span="24">
            <el-radio-group v-model="selectType" size="mini" @input="searchVideo(selectType, 1)">
              <el-radio-button :label="item.type_id" v-for="(item, index) in listTypes" :key="index">{{
                  item.type_name
                }}
              </el-radio-button>
            </el-radio-group>
          </el-col>
        </el-row>
      </div>
    </el-drawer>

    <el-dialog
        title="搜索"
        :visible.sync="dialogVisible"
        width="100%"
        :destroy-on-close="true"
        :before-close="handleClose">
      <div style="margin-top: 5px;">
        <el-row type="flex" class="row-bg" justify="space-around" style="margin-bottom: 8px" v-if="pvType === 1">
          <el-col :span="14">
            <el-radio v-model="searchAll" label="1">搜索全部</el-radio>
            <el-radio v-model="searchAll" label="2">搜索当前</el-radio>
          </el-col>
        </el-row>
        <el-input placeholder="请输入内容" v-model="searchName">
          <el-button slot="append" icon="el-icon-search" @click="searchPv(1)"></el-button>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
  </span>
    </el-dialog>

  </div>
</template>

<script>
import M3u8 from "@/request/expand/av/m3u8";
import MacCms from "../../../request/macCms";
import RoomAsk from "../../../request/expand/tvRoom/index";
import cryptoUtil from "@/utils/cryptoUtil";
import Artplayer from "../../../components/Artplayer.vue";
import DownFile from "../../../request/expand/downFile";
import {jumpToVideoDownload} from "../../../utils/TvUtil.js";

export default {
  name: "av_mobile",
  components: {Artplayer},

  data() {
    return {
      roomAsk: new RoomAsk(),
      macCms: new MacCms(),
      askIndex: new M3u8(),
      askDownFile: new DownFile(),
      listHeight: 0,
      dataList: [],
      title: '....',
      fileName: [],
      link: '',
      currentItem: null,
      totalPages: '',
      tempList: [],
      drawer: false,
      direction: 'rtl',
      listIndex: 0,
      prevPath: '',
      dialogVisible: false,
      searchName: "",
      asideHeight: 0,
      allListShow: false,
      cascaderKey: 101,
      options: [],
      searchAll: "1",
      total: 0,
      currentPage: 1,
      pageSize: 20,

      pvType: 1,
      laoyaParam: {
        "ac": "videolist",
        "pg": 1
      },
      thisItem: null,
      cmsUrl: "",
      selectType: 0,
      listTypes: [],
      pvList: []
    }
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     // 通过 `vm` 访问组件实例,将值传入fromPath
  //     vm.prevPath = from.path
  //   })
  // },

  methods: {
    async resTypeChange(value) {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      this.searchName = ""
      if (value === 1 && this.options.length == 0) {
        this.askIndex.getCascadeData().then(res => {
          this.options = res
        })
      } else {
        const index = this.pvList.findIndex((item) => item.id == value);
        this.cmsUrl = this.pvList[index].host + "/api.php/provide/vod/at/json"
        this.thisItem = this.pvList[index];
        let param = {
          "url": this.cmsUrl
        }
        let data = await this.askData(param, loading);
        if (data) {
          this.listTypes = data.class
        }
      }
      loading.close()
    },
    async askData(data, loading1) {
      try {
        const res = await this.roomAsk.laoYaSearchVideo(data);
        const obj = JSON.parse(res);
        return obj
      } catch (e) {
        console.log("大橙同学")
        loading1.close()
        return null
      }
    },
    async searchVideo(type, pageNumber, searchName) {
      this.currentPage = pageNumber
      this.laoyaParam['t'] = type;
      this.laoyaParam['pg'] = pageNumber;
      this.laoyaParam['wd'] = searchName;
      if (searchName) {
        delete this.laoyaParam['t'];
      }
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        this.laoyaParam['url'] = this.cmsUrl
        let obj = await this.askData(this.laoyaParam, loading);
        this.tempList = []
        this.total = obj.total
        this.pageSize = Number(obj.limit)
        obj.list.forEach(item => {
          this.tempList.push({
            "img": item['vod_pic'],
            "title": item['vod_name'],
            "link": item['vod_play_url'],
          })
          this.drawer = false
          this.dialogVisible = false
        })
        loading.close()
      } catch (E) {
        loading.close()
      }
    },
    changeTree(data) {
      this.loadFileData(data.join("/"), 0)
    },
    /**
     * 视频下载
     */
    downFile() {
      if (this.link == null || this.link == '') {
        this.$message({
          showClose: true,
          message: '请先选择视频~',
          type: 'warning'
        });
        return
      }
      jumpToVideoDownload.call(this, {
        link: this.currentItem.link,
        img: this.currentItem.img,
        title: this.currentItem.title
      });
      // let param = {
      //   "fileName": this.title,
      //   "fileUrl": this.link,
      //   "threadCount": 100
      // }
      // this.askDownFile.addTask(param).then(res => {
      //   if (res.code === 'success') {
      //     this.$message({
      //       showClose: true,
      //       message: '恭喜你，新增成功',
      //       type: 'success'
      //     });
      //   }
      // })
    },
    /**
     * 一起看
     */
    watchTogether() {
      let data = {
        tvrName: "pv_" + this.$store.state.user.userInfo.lUsrName,
        tvrPwd: '123456',
        tvrUrl: this.link
      }
      this.roomAsk.updRoomInfo(data).then(res => {
        if (res.code === 'success') {
          let cryptoName = new cryptoUtil().encryptFun(data.tvrName)
          this.$router.push({
            name: 'tvRoom',
            query: {
              type: "pv",
              tvrName: cryptoName
            }
          })
        }
      })
    },
    /**
     * 销毁播放器
     */
    destroyVideo() {
      this.$refs.artplayer.changeUrl("")
    },
    /**
     * 搜索资源
     */
    async searchPv(pageNumber) {
      if (this.pvType !== 1) {
        return await this.searchVideo(this.selectType, 1, this.searchName)
      }
      this.currentPage = pageNumber
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let data = {
        "fieldConditions": [
          {
            "value": `*${this.searchName}*`,
            "should": true,
            "fieldName": "title",
          }
        ],
        "indexCatalog": "pv",
        "pageNumber": pageNumber,
        "pageSize": 20
      }
      if (this.searchAll === '2') {
        try {
          let cascade = this.$refs.panel.getCheckedNodes(true)
          data.fieldConditions.push({
            "value": `${cascade[0].path.join(">")}`,
            "should": true,
            "fieldName": "type.keyword",
          })
        } catch (e) {
          console.log('大橙同学')
        }
      }
      this.askIndex.searchByCondition(data).then(res => {
        try {
          if (res.resolveList == null) {
            this.$message({
              showClose: true,
              message: '没有搜索到资源嗷~',
              type: 'warning'
            });
            return
          }
          this.tempList = res.resolveList
          this.totalPages = (res.total + 20 - 1) / 20;
          this.total = res.total
          // this.tempList = this.dataList.slice(0, 20)
          this.dialogVisible = false;
          this.dataList = []
        } catch (e) {
          this.$message({
            showClose: true,
            message: '错了哦，这是一条错误消息',
            type: 'error'
          });
        } finally {
          loading.close();
        }
      }).catch(e => {
        loading.close();
      })
    },
    handleClose(done) {
      done();
    },
    playVideo(item) {
      this.currentItem = item
      this.title = item.title
      this.link = item.link.split("$")[1]
      document.title = this.$t('title_') + item.title
      this.askIndex.watchRecord({"title": item.title})
      this.$refs.artplayer.changeUrl(this.link);
    },
    /**
     * 跳转播放
     */
    jumpPlay() {
      window.open(this.$store.getters["systemConfig/getCommon"].videoPlayerAdd + this.link, '_blank')
    },
    /**
     * 大橙加载数据
     * @param fileName
     */
    loadFileData(fileName, index) {
      const loading = this.$loading({
        lock: true,
        text: '飞快的加载数据中~',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.currentPage = 1
      this.listIndex = index
      this.askIndex.getData(fileName).then(res => {
        loading.close();
        this.dataList = res.list
        this.total = res.list.length
        if (res.list != null && res.list.length > 0) {
          this.totalPages = (res.list.length + 20 - 1) / 20;
          this.tempList = this.dataList.slice(0, 20)
        }
        this.drawer = false;
      }).catch(e => {
        loading.close();
      })
    },
    /**
     * 分页
     * @param val
     */
    async handleCurrentChange(val) {
      if (this.pvType === 1) {
        if (this.dataList.length > 0) {
          this.tempList = this.dataList.slice(val * 20 - 20, 20 * val - 1)
        } else {
          this.searchPv(val);
        }
      } else {
        await this.searchVideo(this.selectType, val, this.searchName)
      }

      this.$nextTick(function () {
        const e = document.getElementsByClassName('li');
        if (e.length > 0) {
          e[0].scrollIntoView();
        }
      })
    },
    itemIsChild(item) {
      let linkList = item['link'].split(this.thisItem['listDecorator'])
      return linkList.length > 1
    },
    itemParsingUrl(item) {
      let list = []
      let temp = item.split(this.thisItem['listDecorator'])
      temp.forEach(item => {
        let numberOfEpisodes = item.split("$");
        list.push({
          title: numberOfEpisodes [0],
          link: item
        })
      })
      return list;
    }
  },
  mounted() {
    this.listHeight = window.innerHeight - (document.getElementById("topDiv") ? document.getElementById("topDiv").offsetHeight : 0) - 70
    this.asideHeight = window.innerHeight - 61
    window.onresize = () => {
      this.listHeight = window.innerHeight - (document.getElementById("topDiv") ? document.getElementById("topDiv").offsetHeight : 0) - 70
      this.asideHeight = window.innerHeight - 61
    };

    this.macCms.getList({
      "pageNum": 1,
      "pageSize": 100,
      "type": "pv"
    }).then(res => {
      this.pvList = res.list
    })
    if (this.pvType === 2) {
      if (this.allListShow) {
        this.askIndex.getFileNameData().then(res => {
          this.fileName = res
        })
      } else {
        this.askIndex.getCascadeData().then(res => {
          this.options = res
        })
      }
    }
  },
  computed: {},

  watch: {
    // pvType(newItem, oldItem) {
    //
    // },
  },
  destroyed() {
  }
}
</script>


<style scoped>
/deep/ .el-cascader-panel.is-bordered {
  border: none;
}

/deep/ .el-cascader-menu__wrap {
  height: 90vh;
}

.ul {
  margin: 0;
  padding-left: 0px;
}

.el-header, .el-footer {
  background-color: #c3cfe2;
}

.el-main {
  padding: 0;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.ul1 {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  color: #e6e9f0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  list-style: none;
  padding: 0;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 0;
  white-space: nowrap;
}

.li1 {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  color: #e6e9f0;
  list-style: none;
  font-size: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 10px 0 0 0;
  padding: 0;
  vertical-align: top;
  -webkit-transform: translateZ(0);
  /*display: inline-block;*/
  position: relative;
  width: 100%;
  height: 80px;
  border-radius: 2px;
  white-space: normal;
  background: hsla(0, 0%, 100%, .08);
}

.li1 > a {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  list-style: none;
  white-space: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  display: -webkit-box;
  width: 100%;
  height: 100%;
  /*padding: 0 12px;*/
  overflow: hidden;
  border-radius: 2px;
  font-size: 14px;
  line-height: 18px;
  vertical-align: middle;
  border-color: #f25b20;
  color: #f25b20;
}

.li1 > a > span {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  list-style: none;
  white-space: normal;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  color: #f25b20;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-align: justify;
  text-overflow: ellipsis;
}

.mod_hd {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  /*color: #e6e9f0;*/
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  padding: 0;
  display: flex;
  position: relative;
  margin: 0 15px 13px;
}

.mod_title {
  text-size-adjust: 100%;
  word-wrap: break-word;
  word-break: break-all;
  user-select: none;
  font: 14px/1.5 PingFangSC-Regular, Helvetica Neue, tahoma, arial, sans-serif;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /*color: #e6e9f0;*/
  flex: none;
  max-width: 60%;
  padding-right: 10px;
  overflow: hidden;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.liChoose {
  background: linear-gradient(to top, #a8edea 0%, #fed6e3 100%);
}

.liNoChoose {
  background: #202131;
}

.Ptitle {
  margin: 0;
  padding-left: 5px;
  overflow: hidden; /*超出部分隐藏*/
  word-wrap: break-word;
  text-align: left;
  cursor: pointer;
}

.footerDiv {
  margin-top: 15px;
}
</style>
